import { useEffect } from "react";
import RecipeListItem from "./RecipeListItem";

export default function RecipeList({ recipes, setRecipes }) {
  const primary_ingredients = [
    "coffee",
    "greentea",
    "tea",
    "chocolate",
    "milk",
  ];

  const secondary_ingredients = [
    "ginger",
    "mint",
    "lemon",
    "honey",
    "cinnamon",
    "milk",
  ];

  const generateRecipes = () => {
    const generatedRecipes = [];

    primary_ingredients.forEach((primary) => {
      generatedRecipes.push([primary, primary, primary]);

      secondary_ingredients.forEach((secondary1, index1) => {
        secondary_ingredients.forEach((secondary2, index2) => {
          if (
            index1 <= index2 &&
            secondary1 !== primary &&
            secondary2 !== primary
          ) {
            generatedRecipes.push([primary, secondary1, secondary2]);
          }
        });
      });
    });

    return generatedRecipes;
  };
  
  useEffect(() => {
    const recipes = generateRecipes();
    setRecipes(recipes);
  }, []);

  return (
    <div className="relative bg-background grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 py-4 mx-6 rounded-md">
      {recipes.map((item, index) => (
        <div key={index}>
          <RecipeListItem item={item}></RecipeListItem>
        </div>
      ))}
    </div>
  );
}
