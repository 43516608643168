import { useState } from "react";

export default function RecipeListItem({ item }) {
  const id = item.join('');
  const storedValue = localStorage.getItem(id);
  const [getChecked, setChecked] = useState(() => JSON.parse(storedValue) || false);

  const handleCheckChange = () => {
    setChecked(prevChecked => {
      const newCheckedValue = !prevChecked;
      localStorage.setItem(id, JSON.stringify(newCheckedValue));
      return newCheckedValue;
    });
  };
  
  return (
    <div className="m-2 p-4 bg-secondary text-background">
      <label className="flex items-center cursor-pointer m-auto">
        <span className="mr-2 m-auto">{item.join(' ')}</span>
        <input className="m-auto w-4 h-4 text-background accent-background rounded focus:ring-primary focus:ring-2" type="checkbox" checked={getChecked} onChange={handleCheckChange} />
      </label>
    </div>
  );
}
