export default function HeroSection() {
  return (
    <div className="relative w-screen">
      <img src="/img/city.jpg" alt="Hero" className="hidden md:block w-full background-city" />
      <div className="hidden md:block absolute inset-0 bg-gradient-to-b from-transparent to-primary background-city"></div>
      <div className="w-full md:absolute md:top-0 text-center text-white absolute-query">
        <div className="w-3/4 mx-auto my-8">
          <img src="/img/logo.png" alt="Logo" className="w-80 m-auto" />
          <h1 className="bg-secondary text-md md:text-4xl w-fit m-auto px-8 py-4 text-background font-bold mb-4">Coffee Talk Recipe Checklist</h1>
          <p className="text-lg md:text-xl text-background">If like me, you want a 100% game completion but can't remember all tried recipes, keep track of your trials!</p>
        </div>
      </div>
    </div>
  );
}
