import { useState } from "react";
import "./App.css";
import RecipeList from "./components/recipe/RecipeList";
import Footer from "./components/footer/Footer";
import HeroSection from "./components/cover/HeroSection";

function App() {
  const [recipes, setRecipes] = useState([]);
  return (
    <div className="App bg-primary">
      <HeroSection />
      <RecipeList recipes={recipes} setRecipes={setRecipes} />
      <Footer />
    </div>
  );
}

export default App;
